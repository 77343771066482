.btn_right[data-v-33a5faf7] {
  text-align: right;
  margin-bottom: 10px;
}
.foot[data-v-33a5faf7] {
  margin-top: 10px;
  margin-bottom: 20px;
}
.foot > div[data-v-33a5faf7] {
  float: right;
}